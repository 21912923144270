@import '~@angular/material/prebuilt-themes/indigo-pink.css'; 

body,
html {
  margin: 0 auto;
}

.mat-grid-tile-content {
  justify-content: unset;
}


mat-form-field.mat-form-field.mat-form-field-appearance-outline>div.mat-form-field-wrapper>div.mat-form-field-flex>div.mat-form-field-infix {
  padding: 0.4em 0px
}

mat-form-field.mat-form-field.mat-form-field-appearance-outline>div.mat-form-field-wrapper>div.mat-form-field-flex>div.mat-form-field-infix>span.mat-form-field-label-wrapper {
  top: -1.5em;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: translateY(-0.5em) scale(.50);
  width: 100%;
}

::ng-deep .mat-tab-label {
  height: 27px !important;
  min-height: 5px !important;
  margin: 3px !important;
  width: 90px !important;
  min-width: 5px !important;
}

// .mat-mdc-tab-header { border: 1px solid red; }
//.mat-mdc-tab.mdc-tab { background-color: #6278f1; }
// .mat-mdc-tab-body.mat-mdc-tab-body-active { min-height: 48px; background-color: coral; }
.mat-mdc-tab.mdc-tab {
  &.mdc-tab-indicator--active {
    background-color: #3f51b5;
  }
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: white !important
}

input.mat-input-element {
  height: 45px !important
}

::ng-deep .mat-mdc-form-field-infix {
  min-height: 25px !important
}

//JCG 5/2/2024 -------Page Load Spinner-------------------
.cssload-container {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
}

.cssload-speeding-wheel {
  content: "";
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 63px;
  height: 63px;
  margin: 0 auto;
  border: 6px solid rgb(0, 102, 204);
  border-radius: 50%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: cssload-spin 500ms infinite linear;
  -o-animation: cssload-spin 500ms infinite linear;
  -ms-animation: cssload-spin 500ms infinite linear;
  -webkit-animation: cssload-spin 500ms infinite linear;
  -moz-animation: cssload-spin 500ms infinite linear;
}

@keyframes cssload-spin {
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes cssload-spin {
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-ms-keyframes cssload-spin {
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes cssload-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes cssload-spin {
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
//--------End Page Load Spinner

.loading-text {
  display: block;
  position: absolute;
  left:41%;
  top: 50%;
  width: 300px;
  height: 30px;
  margin: 0 auto;
  font-family: Roboto;
  font-weight: bold;
  font-size: 16pt;
  color: #8c83c9;
}

.validator
{
  color:red;
  background-color: yellow;
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px;
  //box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);  
}

